/*----------------------------------------------------------------------------*\
  #INTERACTIONS
  A more lightweight version of whatinput
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    $target: document.documentElement,
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Set the interaction type as a data attribute
  const _interactionHandler = (event, $target = _settings.$target) => {
    $target.dataset.interactionType = event.type;
  };

  // Public: Destroy module instance
  const destroy = () => {
    document.removeEventListener('touchstart', _interactionHandler);
    document.removeEventListener('mousedown', _interactionHandler);
    document.removeEventListener('keyup', _interactionHandler);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    document.addEventListener('touchstart', _interactionHandler);
    document.addEventListener('mousedown', _interactionHandler);
    document.addEventListener('keyup', _interactionHandler);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}

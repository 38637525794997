/*----------------------------------------------------------------------------*\
  #TOGGLE CLASS
  A simple helper utility for toggling a class
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-toggle',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    triggerEvent: 'change',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Toggle class method
  const _toggleClass = event => {
    const $el = event.currentTarget;
    const className = $el.dataset.toggleClass || 'is-active';
    let $targets = document.querySelectorAll($el.dataset.targetSelector);

    if ($targets.length === 0) {
      $targets = [$el];
    }

    $targets.forEach($target => {
      $target.classList.toggle(className);
    });
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($toggle => {
      const triggerEvent = $toggle.dataset.event || _settings.triggerEvent;
      $toggle.removeEventListener(triggerEvent, _toggleClass);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($toggle => {
      const triggerEvent = $toggle.dataset.event || _settings.triggerEvent;
      $toggle.addEventListener(triggerEvent, _toggleClass);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}

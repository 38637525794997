/*----------------------------------------------------------------------------*\
  #AUTOCOMPLETE
  Populates a matching datalist with results returned from a JSON endpoint.

  Options
  -------
  - Endpoint (required)
    The endpoint must be defined as a data atribute on the input tag.
  - Min Chars (optional)
    Default minimum number of characters before a request is made is 3.
    this can be changed by setting a data attribute of `data-min-chars` on
    the input tag.
\*----------------------------------------------------------------------------*/

/**
 * IMPORTS
 */

import { TE } from 'util/throw-error';
import { createEl } from 'util/create-el';

/**
 * PRIVATE METHODS
 */

// Private: Fetch results from endpoint
const _fetchResults = async event => {
  const $input = event.currentTarget;
  const $datalist = $input.list;
  const minChars = $input.dataset.minChars || 3;
  const endpoint = $input.dataset.endpoint + $input.value;

  if (!$datalist) TE('No datalist is attached to this input.');

  // Return if the value is an exact match of the datalist
  if (datalistHasOption($datalist, $input.value)) return;

  // Return if we aren't exceeding the minChars (either specified data attribute, or defaults to 3).
  if ($input.value.length < minChars) {
    _removeListData($datalist);
    return;
  }

  if (!endpoint)
    TE(
      'No endpoint defined. This should be set as a data attribute on the input.'
    );

  try {
    const response = await fetch(endpoint);
    const json = await response.json();

    if (json !== null) {
      // Dispatch an event
      const eventData = {
        detail: { $input, json },
      };

      const fetchEvent = new CustomEvent('autocomplete:fetch', eventData);
      $input.dispatchEvent(fetchEvent);
    }
  } catch (error) {
    TE(error);
  }
};

// Private: Change handler
const _changeHandler = event => {
  const $input = event.currentTarget;

  // Dispatch an event
  const eventData = { detail: { $input } };
  const changeEvent = new CustomEvent('autocomplete:change', eventData);
  $input.dispatchEvent(changeEvent);
};

// Private: Remove any exsting options in the datalist
const _removeListData = $datalist => {
  while ($datalist.firstChild) $datalist.removeChild($datalist.firstChild);
};

// Private: Compare values of two HTML Collections to see if they match
const _optionsMatch = ($newOptions, $existingOptions) => {
  const existingOptions = Array.from($existingOptions).map(
    $option => $option.value
  );
  const newOptions = Array.from($newOptions).map($option => $option.value);

  return JSON.stringify(existingOptions) === JSON.stringify(newOptions);
};

// Private: Create an array of Option elements from an array
const _createOptionsFromArray = array => {
  if (!(array instanceof Array)) TE('This is not an instance of an array.');

  const $options = array.map(option => {
    const $option = createEl('option', {
      value: option.value,
    });

    for (const key of Object.keys(option.attributes)) {
      $option.setAttribute(key, option.attributes[key]);
    }

    return $option;
  });

  return $options;
};

/**
 * PUBLIC METHODS
 */

// Public: Check if a specified option is in the datalist
const datalistHasOption = ($datalist, value) => {
  return Array.from($datalist.options).find(
    $option => $option.value.toLowerCase() === value.toLowerCase()
  );
};

// Public: Update the datalist with options from an array
const updateDatalist = ($input, array) => {
  const $datalist = $input.list;
  const $newOptions = _createOptionsFromArray(array);

  // If the new options & existing datalist match then return
  if (_optionsMatch($datalist.options, $newOptions)) return;

  _removeListData($datalist);
  $newOptions.map($newOption => $datalist.appendChild($newOption));

  // Dispatch an event
  const eventData = { detail: { $input } };
  const updateEvent = new CustomEvent('autocomplete:update', eventData);
  $input.dispatchEvent(updateEvent);
};

// Public: Default module export
const Autocomplete = options => {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-autocomplete',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.removeEventListener('keyup', _fetchResults);
      $input.removeEventListener('change', _changeHandler);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.addEventListener('keyup', _fetchResults);
      $input.addEventListener('change', _changeHandler);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Module exports
export {
  Autocomplete as default,
  Autocomplete,
  updateDatalist,
  datalistHasOption,
};

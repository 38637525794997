/*----------------------------------------------------------------------------*\
  # Visitor Check-In
\*----------------------------------------------------------------------------*/

import { Autocomplete, updateDatalist } from 'shared/autocomplete';
import { TE } from 'util/throw-error';
import Rails from '@rails/ujs';

// PublicL Default module export
export const VisitorCheckIn = options => {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-visitorCheckIn',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Instansiate the autocomplete module
  const _Autocomplete = new Autocomplete(_settings);

  // Private: Transform data to options
  const _autocompleteFetchHandler = event => {
    const $input = event.detail.$input;
    const json = event.detail.json;

    const array = _transformData(json);
    updateDatalist($input, array);
  };

  // Private: Transform data into the correct format to generate `<option>` elements
  const _transformData = json => {
    if (!(json instanceof Array)) TE('This is not an instance of an array.');

    const array = [];

    json.map(user => {
      // Output 'Joe Bloggs, Microsoft' if company is available, otherwise fallback to 'Joe Bloggs'.
      const name = user.company_name
        ? `${user.full_name_with_id}, ${user.company_name}`
        : user.full_name_with_id;

      // Action either check-in or check-out depending on state.
      const action = user.check_in_path || user.check_out_path;

      // Create a little variable for us to use to reference if we are to check-in or check-out
      const checkInOrOut = user.check_in_path ? 'in' : 'out';

      const holderId = user.holder_id;

      const data = {
        value: name,
        attributes: {
          'data-action': action,
          'data-check': checkInOrOut,
          'holder-id': holderId,
        },
      };

      array.push(data);
    });

    return array;
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _Autocomplete.destroy();

    _settings.$selectors().forEach($el => {
      $el.removeEventListener('autocomplete:fetch', _autocompleteFetchHandler);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _Autocomplete.init();

    _settings.$selectors().forEach($el => {
      $el.addEventListener('autocomplete:fetch', _autocompleteFetchHandler);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default VisitorCheckIn;

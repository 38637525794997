/*----------------------------------------------------------------------------*\
  #CHECK-IN / CHECK-OUT
\*----------------------------------------------------------------------------*/

import {
  Autocomplete,
  updateDatalist,
  datalistHasOption,
} from 'shared/autocomplete';
import { TE } from 'util/throw-error';
import Rails from '@rails/ujs';

// PublicL Default module export
export const CheckIn = options => {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-checkIn',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    autoSubmit() {
      return this.$selectors()[0].dataset.autosubmit !== 'false';
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Instansiate the autocomplete module
  const _Autocomplete = new Autocomplete(_settings);

  // Private: Transform data to options
  const _autocompleteFetchHandler = event => {
    const $input = event.detail.$input;
    const json = event.detail.json;

    const array = _transformData(json);
    updateDatalist($input, array);
  };

  // Private: Transform data into the correct format to generate `<option>` elements
  const _transformData = json => {
    if (!(json instanceof Array)) TE('This is not an instance of an array.');

    const array = [];

    json.map(user => {
      // Output 'Joe Bloggs, Microsoft' if company is available, otherwise fallback to 'Joe Bloggs'.
      const name = user.company_name
        ? `${user.full_name_with_id}, ${user.company_name}`
        : user.full_name_with_id;

      // Action either check-in or check-out depending on state.
      const action = user.check_in_path || user.check_out_path;

      // Create a little variable for us to use to reference if we are to check-in or check-out
      const checkInOrOut = user.check_in_path ? 'in' : 'out';

      const data = {
        value: name,
        attributes: {
          'data-action': action,
          'data-check': checkInOrOut,
        },
      };

      array.push(data);
    });

    return array;
  };

  // Private: Submit form - prevent form submission if value doesn't exist in the datalist
  const _submitForm = event => {
    event.preventDefault();
    event.stopPropagation();

    const $form = event.currentTarget.closest('form');
    const $input = $form.querySelector(_settings.selector);

    const $datalist = $input.list;
    const $selectedOption = datalistHasOption($datalist, $input.value);

    // Don't do anything if there is no input value
    if ($input.value.length === 0) return;

    // Determine whether we are checking-in or out by inspecting the form action
    const checkIn = $form.action.match(/check_ins/g) !== null;
    const checkOut = !checkIn;

    // If the input value isn't an option provided
    if (!$selectedOption) {
      // If we're checking-out we return
      if (checkOut) return;

      // If it's a not 'submit' event (ie. 'change'), then we return
      if (event.type !== 'submit') return;

      const checkInAsVisitor = confirm(
        'We couldn’t find you in the list of operatives expected to work on site today. ' +
          'Tap OK to sign in as a visitor and report to the Site Office / see the Site Manager ' +
          'or tap Cancel to try and search again.'
      );

      // If user Cancels then return
      if (checkInAsVisitor === false) return;

      // Redirect the user to visitor check-in
      const baseUrl = window.location.href.replace('check_ins', 'visitors');
      window.location.href = `${baseUrl}?full_name=${$input.value}`;
    }

    // Update the form action to the value provided by the selected user
    $form.action = $selectedOption.dataset.action;

    $form.removeEventListener('submit', _submitForm);

    if (_settings.autoSubmit()) {
      $form.removeEventListener('autocomplete:change', _submitForm);
    }

    $form.submit();
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _Autocomplete.destroy();

    _settings.$selectors().forEach($el => {
      const $form = $el.closest('form');
      $el.removeEventListener('autocomplete:fetch', _autocompleteFetchHandler);
      $form.removeEventListener('submit', _submitForm);

      if (_settings.autoSubmit()) {
        $el.removeEventListener('autocomplete:change', _submitForm);
      }
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _Autocomplete.init();

    _settings.$selectors().forEach($el => {
      const $form = $el.closest('form');

      $el.addEventListener('autocomplete:fetch', _autocompleteFetchHandler);
      $form.addEventListener('submit', _submitForm);

      if (_settings.autoSubmit()) {
        $el.addEventListener('autocomplete:change', _submitForm);
      }
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default CheckIn;

/*----------------------------------------------------------------------------*\
  #AUTOCOMPLETE HIDDEN VALUE
  Whilst the HTML5 datalist is great, it also shows the 'value' attribute of
  all of the `<option>` elements in most browsers. This is rarely something
  we want to display to the end user.

  This JavaScript module deals with this issue by allowing you to set the
  value a data attribute, and then  update a hidden field with the value.
  This is triggered on 'change' of the input selector.

  Options
  -------
  - Hidden Field (required)
    The hidden field must be defined as a data atribute on the input tag.
  - Value Attribute (required)
    The attribute where the value is stored must be defined as a data
    attribute.

  Example
  -------
  <input type="hidden" id="coffee_choice">

  <input
    type="text"
    class="js-autocompleteHiddenValue"    JS hook for this module.
    list="coffees"                        The id of the datalist.
    data-value-attribute="data-value"     How the value is stored on each `<option>`.
    data-hidden-field="coffee_choice">    The id of the hidden field.

  <datalist id="coffees">
    <option data-value="qwertyuiop">Americano</option>
    <option data-value="asdfghjkl">Cappucino</option>
    <option data-value="zxcvbnm">Cafe Latte</option>
  </datalist>
\*----------------------------------------------------------------------------*/

// Imports
import { TE } from 'util/throw-error';
import { datalistHasOption } from './autocomplete';

// Private: Change handler event
const _changeHandler = event => {
  const $input = event.currentTarget;

  /**
   * Validate the data we have and ensure everything is tickety-boo.
   * Otherwise we throw some insightful errors.
   */
  const $datalist = $input.list;
  if (!$datalist) TE('No datalist is attached to this input.');

  const hiddenValueAttribute = $input.dataset.valueAttribute;
  if (!hiddenValueAttribute)
    TE(
      'Could not find a value reference on this input. This should be set as a data attribute (eg. data-value-attribute="data-value").'
    );

  const $hiddenField = document.getElementById($input.dataset.hiddenField);
  if (!$hiddenField)
    TE(
      'Could not locate a hidden field to update the value of. This should be set as a data attribute (eg. data-hidden-field="id").'
    );

  const $option = datalistHasOption($datalist, $input.value);
  if (!$option) return;

  const value = $option.getAttribute(hiddenValueAttribute);
  $hiddenField.value = value;
};

// Public: Default module export
const AutocompleteHiddenValue = options => {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-autocompleteHiddenValue',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.removeEventListener('change', _changeHandler);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.addEventListener('change', _changeHandler);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Module exports
export default AutocompleteHiddenValue;
